import {RenderPicto} from "../assets/svg/picto";
import {renderForm} from "./frames/form";

export class Feedbacks{
    constructor(iframeSrc) {
        console.log(iframeSrc);
        this.iframeSrc = iframeSrc;
    }

    init(){
        this.renderButton();
        this.openModal = this.openModal.bind(this)
    }

    renderButton(){
        let button =  document.createElement( 'div' );
        button.classList.add('usrp-fb-1');
        button.insertAdjacentHTML('beforeend', RenderPicto());

        button.addEventListener('click',  (event) => {
            this.openModal();
        });
        document.body.appendChild(button);

    }

    openModal(){
        let modal = document.createElement('div');
        modal.classList.add('modal-feedbacks');
        modal.insertAdjacentHTML('beforeend', renderForm(this.iframeSrc));
        document.body.appendChild(modal);

        let buttonClose = document.createElement( 'span' );
        buttonClose.classList.add('btn-close-feedbacks');
        buttonClose.textContent ='Fermer';
        buttonClose.addEventListener('click',  (event) => {
            modal.remove()
        });
        modal.append(buttonClose)
    }


}
